.inputSearch {
  background-color: transparent;
  text-overflow: ellipsis !important;
}


.inputSearch:focus-visible {
  border: none;
  outline: none;
}
