.icon {
  width: 20rem;
  height: 20rem;
  color: theme('colors.primaryYellow');
}

.routeLink {
  font-size: 3rem;
}

.routeLink:hover {
  color: theme('colors.primaryYellow');
}
