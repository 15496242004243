.inputSearch {
  color: theme("colors.primaryBlack");
  background-color: transparent;
  border-left: 1px solid white;
  width: 100%;
  max-width: 600px;
}
.searchAnimation {
  animation: fadeIn .6s;
  margin-top: 40px;
  overflow: hidden;
  overflow-y: auto;
}
@keyframes fadeIn {
  from {
    opacity: 1;
    transform: translateY(45%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */

/* @media screen and (min-width: 500px) {
  .homeContainer {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
  }
} */
